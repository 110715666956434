<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">ID Pendaftaran {{ listData.uid }}</h6>
        </b-col>
        <b-col lg="6" cols="7" style="display:flex;justify-content:flex-end">
              <base-button  @click="back()">Kembali</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container v-if="isLoading">
      <h2 class="text-center" style="margin-top: 30vh;">Loading...</h2>
    </b-container>

    <b-container fluid class="mt--5" v-if="!isLoading">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <h6 class="surtitle">Overview</h6>
              <h5 class="h3 mb-0">{{ listData.name  }}</h5>

              <div class="card-body">

                <ul class="list-group">
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>UID</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.uid }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Jenis Industri</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company_type_name }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Nama Industri</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.name }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Nomor SIUP</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.siup_number }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Penanggung Jawab SIUP</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.siup_on_behalf }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Lampiran SIUP</strong>
                      </div>
                      <div class="col-lg-9">
                        <a
                          :href="
                            listData.siup_attachment
                              ? listData.siup_attachment
                              : ''
                          "
                          target="blank"
                        >
                          <span>
                            <i class="ni ni-cloud-download-95 text-primary"></i>
                          </span>
                          <span>{{
                            listData.siup_attachment === "" ||
                            listData.siup_attachment === undefined ||
                            listData.siup_attachment === null
                              ? " Belum ada  File Terupload"
                              : " File Terupload"
                          }}</span>
                        </a>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Telepon</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.phone }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Email</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.email }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Alamat</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.address }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Jalan</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.street }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Provinsi</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.province_name }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Kabupaten / Kota</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.regency_name }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Status</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.status }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Dibuat</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ formatDate(listData.created_at) }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Diubah</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ formatDate(listData.updated_at) }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>



    

  </div>
</template>
<script>
import API from "../../../api/base_url.js"
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import Modal from "@/components/Modal.vue";
import moment from 'moment'



export default {
  components: {
    BaseHeader,
    RouteBreadCrumb,
    Modal
  },
  data() {
    return {
      isLoading:true,
      listData: {}
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    formatDate(date) {
        return moment(date).format("DD/MM/YYYY");
    },
    getData() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
      console.log(this.$route.params.id);
      API.get(`web/master/company?uid=${this.$route.params.id}` , {headers})
      .then(({data:content}) => {
        console.log(content.data,'uhuuuu');
        this.listData = content.data
        this.isLoading = false
      })
      .catch((err) => {
        console.log(err);
      })
    },
    back() {
      this.$router.push("/admin-company");
    },
  },
};
</script>
<style scoped>

.mdl-contents {
overflow-y: auto;
max-height: 90%;
}
.mdl-header {
height: 2.5rem;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
column-gap: 1rem;
}
.mdl-header i {
font-size: 30px;
color: #00bcd4;
color: #FFFFFF;
}
.mdl-header p {
margin-bottom: 0;
font-size: 28px;
font-weight: 600;
text-transform: uppercase;
color: #000000;
}

.announcement-modal-backgrounds {
background: #ff9999;
}

</style>
